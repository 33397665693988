.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 95px;
}

.login .content {
  width: 30%;
  min-height: 500px;
}

.login .content .header {
  text-align: center;
}

.login .content .input-form {
  text-align: center;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }

  .login .content {
    width: 80%;
    min-height: 500px;
  }

  .login .content .header {
    text-align: center;
  }

  .login .content .input-form {
    text-align: center;
    margin-top: 40px;
  }
}
