:root body {
  --purple: #9545eb;
  --white: #ffffff;
  --black: #111827;
  --black2: #687083;
}

#children,
#fill-window {
  font-family: Inter !important;
}

.bg-dark {
  background-color: var(--black);
}
.cl-dark2 {
  color: var(--black2);
}
.container {
  margin-left: 118px;
  margin-right: 118px;
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
}

.fill-window .navbar {
  height: 90px;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 2;
  border-bottom: 1px solid whitesmoke;
  box-shadow: 0px 1px 5px 0px rgb(173, 173, 173);
}

.fill-window .navbar .menu {
  font-size: 18px;
  font-weight: 500;
  padding-right: 50px;
}

.fill-window .children {
  margin-top: 90px;
  padding-bottom: 90px;
  background-color: white;
}

.fill-window .navbar .company-container {
  text-align: center;
  display: flex;
  align-items: center;
}

.fill-window .navbar .company-container img {
  width: 100%;
  padding-right: 30px;
}

.fill-window .search-bar {
  transition: 0.5s;
  min-height: 90px;
  height: auto;
  background-color: var(--purple);
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: 0.5;
  padding-bottom: 10px;
}

.fill-window .search-bar .content {
  margin-top: 20px;
}

.fill-window .search-bar .content .result-search {
  cursor: pointer;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.fill-window .search-bar .content .result-search:hover {
  background-color: #687083;
}

.fill-window .footer {
  height: 200px;
  background-color: #051239;
  padding-top: 32px;
  padding-bottom: 32px;
}

.fill-window .footer .content {
  height: 100%;
  color: white;
}

.fill-window .search-bar .ant-input-affix-wrapper .ant-input {
}

.fill-window .footer .content .sosmed img {
  margin-left: 20px;
}
