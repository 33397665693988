/*.swal2-container.swal2-center.swal2-container-me.swal2-backdrop-show {
  z-index: 9999;
}*/

/*div .swal2-container-me {
  z-index: 9999 ;
}*/
.swal2-container.swal2-center.swal2-backdrop-show.swal2-container-me {
  /*.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {*/
  /*background: rgba(0,0,0,.4);*/
  /*background: 'red';*/
  z-index: 9999;
}

.swal2-container-me {
  /*.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {*/
  /*background: rgba(0,0,0,.4);*/
  /*background: 'red';*/
  z-index: 9999;
}

.swal-container {
  z-index: 2000;
}

/* .MuiTableRow-root td:first-child { */
/*border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;*/
/*border: 1px solid #D1D5DC;*/
/* } */

/* inter-300 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/inter-v8-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/inter-v8-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/inter-v8-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/inter-v8-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/inter-v8-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/inter-v8-latin-300.svg#Inter") format("svg");
  /* Legacy iOS */
}

/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/inter-v8-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/inter-v8-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/inter-v8-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/inter-v8-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/inter-v8-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/inter-v8-latin-regular.svg#Inter") format("svg");
  /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/inter-v8-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/inter-v8-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/inter-v8-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/inter-v8-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/inter-v8-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/inter-v8-latin-700.svg#Inter") format("svg");
  /* Legacy iOS */
}

/* inter-900 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/inter-v8-latin-900.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/inter-v8-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/inter-v8-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/inter-v8-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/inter-v8-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/inter-v8-latin-900.svg#Inter") format("svg");
  /* Legacy iOS */
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/new-helvetica-neue/helvetica-neue-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/new-helvetica-neue/helvetica-neue-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/helvetica-neue/HelveticaNeue.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/new-helvetica-neue/helvetica-neue-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/new-helvetica-neue/helvetica-neue-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/new-helvetica-neue/helvetica-neue-regular.svg#Helvetica Neue")
      format("svg");
  /* Legacy iOS */
}

.MuiTableCell-alignRight {
  text-align: right;
  flex-direction: row !important;
}
