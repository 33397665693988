.detail-berita .title-top {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 5px;
}

.detail-berita .berita-lainya {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin-top: 50px;
}

.detail-berita #content span,
.detail-berita #content p {
  font-family: "Inter" !important;
  color: black !important;
  text-align: justify !important;
}

.detail-berita .detail-berita-kanan h2 {
  color: #687083;
  font-weight: 700;
  font-size: 20px;
}

.detail-berita .detail-berita-kanan .content {
  color: #687083;
  display: flex;
  margin-bottom: 15px;
  transition: 0.2s;
  cursor: pointer;
  flex-direction: row;
}

.detail-berita .detail-berita-kanan .content h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 6px;
  margin-bottom: 0px;
}

.detail-berita .detail-berita-kanan .content:hover {
  margin-left: 10px;
}

.detail-berita .detail-berita-kanan .content img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  flex: 1;
}

.detail-berita .wrapper {
  transition: 0.2s;
  cursor: pointer;
}

.detail-berita .wrapper h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.detail-berita .wrapper:hover {
  margin-top: -10px;
}
