.berita-page .banner {
  position: relative;
  height: 560px;
}

.berita-page img {
  width: 100%;
  height: auto;
}

.berita-page .banner .content {
  position: absolute;
  top: 240px;
  left: 120px;
  color: white;
  width: 600px;
}

#berita-page .banner .content p,
#berita-page .banner .content span {
  font-family: "Inter" !important;
  color: white !important;
  background-color: transparent !important;
}

.berita-page .banner .gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.berita-page .banner .content h1 {
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

.berita-page .banner .content p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.berita-page .banner .content button {
  width: 214px;
  height: 48px;
  border-radius: 6px;
  font-weight: 500;
}

.berita-page .berita-atas {
  margin-top: 60px;
}

.berita-atas .image-wrapper {
}

.berita-page .berita-atas .left {
  margin-right: 7px;
  cursor: pointer;
  transition: 0.2s;
}

.berita-page .berita-atas .left:hover {
  margin-top: -10px;
}

.berita-page .berita-atas .right {
  cursor: pointer;
  transition: 0.2s;
  margin-left: 7px;
}

.berita-page .berita-atas .right:hover {
  margin-top: -10px;
}

.berita-page .berita-atas img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.berita-page .desc {
  margin-top: 16px;
}

.berita-page .desc .publisher {
}

.berita-page .desc h2 {
  margin-top: 12px;
  margin-bottom: 5px;
}

.berita-page .berita-atas {
  height: 430px;
}

.berita-page .berita-atas .berita-right .wrapper {
  cursor: pointer;
  transition: 0.2s;
}

.berita-page .berita-atas .berita-right .wrapper:hover {
  margin-left: 15px;
}

.berita-page .berita-atas .berita-right .wrapper .content h3 {
  margin-top: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}

.berita-page .berita-terbaru {
  margin-top: 60px;
}

.berita-page .berita-lainya {
  text-align: center;
  margin-top: 30px;
}

.berita-page .berita-terbaru .content {
  cursor: pointer;
  transition: 0.2s;
  height: 380px;
}

.berita-page .berita-terbaru .content:hover {
  margin-top: -10px;
}

.berita-page .berita-terbaru h1 {
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 32px;
}

.berita-page .berita-terbaru .desc h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.berita-page .berita-terbaru img {
  border-radius: 10px;
  height: 240px;
  object-fit: cover;
}

.berita-page .subscribe-wrapper {
  margin-top: 60px;
  background-color: #051239;
  height: 240px;
}

.berita-page .subscribe {
  height: 100%;
}

.berita-page .subscribe .button {
  background-color: #9545eb;
  margin-left: 10px;
  border: 1px solid #9545eb;
  font-weight: 500;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.berita-page .subscribe h1 {
  font-weight: 700;
  font-size: 48px;
  color: white;
  width: 720px;
}

.berita-page .iklan-terbaru {
  margin-top: 60px;
  height: 530px;
  margin-bottom: 50px;
}

.berita-page .iklan-terbaru .iklan-terbaru-content {
  cursor: pointer;
  transition: 0.2s;
}

.berita-page .iklan-terbaru .iklan-terbaru-content:hover {
  margin-top: -10px;
}

.berita-page .iklan-terbaru img {
  border-radius: 10px;
  height: 279px;
  object-fit: cover;
}

.berita-page .iklan-terbaru h1 {
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 40px;
}
