.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 95px;
}

.login .content {
  min-height: 500px;
}

.login .content .header {
  text-align: center;
}

.login .content .input-form {
  text-align: center;
  margin-top: 40px;
}