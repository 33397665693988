.tentang-kami {
  margin-top: -10px;
}

.tentang-kami .banner {
  background-image: url("../../../assets/img/banner_tentang_kami.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 515px;
  display: flex;
  align-items: center;
}

.tentang-kami .button {
  background-color: #9545eb;
  font-size: 18px;
  border-radius: 10px;
  color: white;
  width: 290px;
  height: 56px;
}

.tentang-kami .gallery {
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #f9fafb;
  height: 600px;
  margin-right: 0px;
}

.layanan-kami-wrapper {
  background-color: #f9fafb;
}

.tentang-kami .layanan {
  margin-top: 80px;
  padding-bottom: 30px;
}

.tentang-kami .simfoni {
  height: 494px;
  display: flex;
  flex-direction: row;
  padding-top: 61px;
  gap: 16px;
}

.tentang-kami .simfoni .image-wrapper {
  position: relative;
  width: 608px;
}

.tentang-kami .simfoni .image-wrapper .image-utilities1 {
  position: absolute;
  margin: auto;
  left: 50px;
  top: 15px;
  width: 270px;
  height: 165px;
  object-fit: cover;
  border-radius: 3px;
}

.tentang-kami .simfoni .image-wrapper .image-utilities2 {
  position: absolute;
  margin: auto;
  right: 52px;
  bottom: 45px;
  width: 270px;
  height: 165px;
  border-radius: 3px;
  object-fit: cover;
}

.tentang-kami .simfoni .image-wrapper .image-utilities3 {
  position: absolute;
  margin: auto;
  left: 32px;
  top: 15px;
  width: 287px;
  height: 180px;
  object-fit: cover;
  border-radius: 3px;
}

.tentang-kami .simfoni .image-wrapper .image-utilities4 {
  position: absolute;
  margin: auto;
  right: 30px;
  bottom: 47px;
  width: 287px;
  height: 180px;
  object-fit: cover;
  border-radius: 3px;
}

.tentang-kami .simfoni .content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tentang-kami .simfoni .content h1 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 24px;
}

.tentang-kami .simfoni .content p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #687083;
}

.tentang-kami .layanan .content {
  border-left: 1px solid rgb(226, 226, 226);
  padding-left: 20px;
  padding-right: 30px;
}

.tentang-kami .layanan .content .head {
  padding-left: 19px;
  margin-left: -23px;
}

.tentang-kami .partner-kami {
  margin-top: 50px;
  text-align: center;
  background-color: #f9fafb;
  padding-top: 50px;
  padding-bottom: 50px;
}

.tentang-kami .banner-footer {
  margin-bottom: -92px;
  position: relative;
  height: 407px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}
