.landing-header {
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .header-logo-mpis {
    z-index: 2;
  }
  .landing-header-menu-container {
    display: flex;
    flex-direction: row;
    padding-top: 24px;
  }
  .landing-header-nav-menu-container {
    display: flex;
    flex-direction: row;
    margin-right: 40px;
  }
  /* .nav-menu {
    background-color: #642c8c;
  } */
  .header-button-action {
    color: white;
    border-color: white;
    border-radius: 8px;
    text-transform: none;
  }
  .button-language {
    padding: 4px;
    color: black;
    background: white;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    font-size: 16px;
  }
  .button-nav {
    padding: 4px;
    color: white;
    border: solid 1px white;
    background: transparent;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    font-size: 16px;
  }
  .button-language:hover {
    background-color: rgba(255, 255, 255, 0.655);
  }
}
.landing-hero {
  position: relative;
  background-color: #642c8c;
  /* height: 100vh; */
  /* border-top: 40px solid #e80088; */
  padding: 40px 120px 80px;
  overflow: hidden;
  .button-share {
    z-index: 10;
    position: absolute;
    right: 120px;
    bottom: 80px;
    width: 40px;
    height: 40px;
  }
  .landing-hero-blog-detail {
    max-height: 650px;
  }
  .hero-content-container {
    margin-top: 68px;
    position: relative;
    z-index: 3;
  }
  .share-dropdown {
    position: absolute;
    right: 120px;
    bottom: 135px;
    background: white;
    padding: 18px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    min-width: 450px;
  }
  .share-dropdown-quotation {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: -10px;
    right: 0;
    transform: translateX(-50%);
  }
  .share-dropdown-social-media-container {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    justify-content: flex-end;
  }
  .url-copy-containter {
    display: flex;
    align-items: center;
  }
  .button-copy {
    background-color: #fd7043;
    color: white;
    border: none;
    cursor: pointer;
    text-transform: none;
  }
}
.landing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 120px 79px;
  .publisher-card {
    display: flex;
    justify-content: center;
    position: relative;
    .card-initials {
      font-size: 26;
      width: 50px;
      height: 50px;
      background: #007bff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }
    .publisher-card-hover {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 254px;
      z-index: 5;
      background: white;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      .quotation {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgba(255, 255, 255, 0.9);
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
      .publisher-name {
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
        margin: 0;
        margin-bottom: 6px;
      }
      .publisher-address {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        margin: 0;
      }
    }
  }
  .form-contact {
    background-color: #652d8d;
    box-shadow: 20px 20px 40px 0px #e8008833;
    padding: 80px;
  }
}

.landing-footer {
  background: #652d8d;
  border-top: 8px solid transparent;
  border-image: linear-gradient(
    270deg,
    #e80088 0%,
    rgba(232, 0, 136, 0) 130.36%
  );
  border-image-slice: 1;
  /* max-height: 600px; */
  padding: 88px 120px;
  .quote-thumbnail {
    z-index: 2;
  }
}
.landing-nav-bar {
  min-width: 40vw;
  padding-top: 60px;
  height: 100vh;
  /* background: #e8008796; */
  .landing-nav-logo {
    display: none;
    /* display: flex;
    justify-content: center;
    margin-bottom: 16px;
    background: #652d8d;
    padding: 8px 20px; */
  }
  .landing-header-nav-menu {
    /* background: linear-gradient(67.45deg, #652d8d 25.08%, #e80088 112.47%); */
    background: #e80088;
    color: white;
    padding: 8px 20px;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 0;
    .nav-menu {
      color: white;
    }
  }
  .landing-header-nav-action {
    background: #652d8d;
    color: white;
    padding: 8px 20px;
    margin-bottom: 12px;
    text-transform: none;
    text-align: left;
    width: 100%;
    border-radius: 0;
    .MuiButton-label {
      justify-content: start;
      font-weight: 400;
    }
  }
  .nav-divider {
    background: linear-gradient(67.45deg, #652d8d 25.08%, #e80088 112.47%);
    margin: 24px 12px;
  }
}
@media only screen and (max-width: 768px) {
  .landing-header {
    align-items: center;
  }
  .landing-hero {
    padding: 10px 30px 20px;
    .button-share {
      right: 30px;
      bottom: 20px;
    }
    .share-dropdown {
      right: 30px;
      bottom: 75px;
      min-width: 0;
      width: 360px;
    }
    .MuiOutlinedInput-input {
      padding: 12px 8px;
      font-size: 12px;
    }
    .button-copy {
      font-size: 12px;
    }
  }
  .landing-content {
    padding: 20px 30px;
    .form-contact {
      padding: 30px;
    }
    .publisher-card {
      .publisher-card-hover {
        width: 200px;
      }
    }
  }
  .landing-footer {
    padding: 20px 30px;
    .quote-thumbnail {
      height: 10px;
    }
  }
}
