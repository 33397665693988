.berita h1 {
  font-size: 36px;
  margin-top: -15px;
}

.berita .content-right {
  margin-left: 20px;
}

.berita .content-right h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 6px;
}

.berita .content-right .desc p, .berita .content-right .desc span  {
  color: #687083 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-top: 0px !important;
}

.berita .wrapper {
  transition: 0.3s;
}

.berita .wrapper:hover {
  transition: 0.3s;
  margin-top: -20px;
  border-radius: 10px;
}
