.edukasi {
  padding-top: 80px;
}

.edukasi h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}

.edukasi .content {
  position: absolute;
  bottom: 5%;
  left: 24px;
}

.edukasi .row-content h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
}

.edukasi .row-content p {
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  /* or 150% */

  text-align: justify;
}

.edukasi .content h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.edukasi .content h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.edukasi .gap {
  margin-top: 60px;
  margin-bottom: 60px;
  border: 1px solid #e4e7eb;
}

.edukasi .banner-wrapper {
  cursor: pointer;
}

.edukasi .banner-wrapper:hover {
  opacity: 0.8;
}

.edukasi .image {
  width: 100%;
  height: 100%;
  /* position: absolute; */
  object-fit: cover;
  border-radius: 10px;
}
