.login-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 95px;
}

.login-admin .content {
  width: 30%;
  min-height: 500px;
}

.login-admin .content .title {
  font-size: 30px;
  margin-top: 40px;
}

.login-admin .content .header {
  text-align: center;
}

.login-admin .content .input-form {
  text-align: center;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .login-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }

  .login-admin .content {
    width: 80%;
    min-height: 500px;
  }

  .login-admin .content .title {
    font-size: 20px;
    margin-top: 40px;
    font-weight: bold;
  }

  .login-admin .content .header {
    text-align: center;
  }

  .login-admin .content .input-form {
    text-align: center;
    margin-top: 40px;
  }
}
