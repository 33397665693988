div.slick-slide.slick-active.slick-current {
  display: flex;
  justify-content: center;
}

.slick-dots {
  top: 343px;
  left: -307px;
}

.slick-dots li.slick-active button:before {
  color: #fe1a9b !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.dashboard .banner {
  background-image: url("../../../assets/img/banner.webp");
  height: 690px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

.dashboard .banner.nadaku {
  background-image: url("../../../assets/img/bannerNadaku.webp");
  height: 690px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dashboard .banner.nadaku-mobile {
  background-image: url("../../../assets/img/bannerNadaku.webp");
  height: 490px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.title {
  font-size: 16px;
}

.dashboard .banner img {
  width: 100%;
  height: 100%;
}

.dashboard .banner .content {
  width: 800px;
  margin-left: 118px;
}

.dashboard .banner .content h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 72px;
  margin: 0;
  margin-bottom: 64px;
}

.dashboard .banner .content .ant-select-selection-search input,
.dashboard .banner .content .ant-select-selection-placeholder,
.dashboard .banner .content .ant-select-selection-item {
  margin-left: 45px;
}

.dashboard .banner .content .search-wrapper {
  width: 400px;
  display: flex;
  align-items: center;
  background-color: white;
  height: 64px;
  width: 480px;
  position: relative;
  border-radius: 6px;
}

.dashboard .banner .content .search-wrapper img {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
}

.dashboard .banner .content h1 {
  color: white;
  font-size: 60px;
}

.dashboard .partner {
  height: 271px;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
}

.dashboard .partner .title {
  width: 600px;
  font-size: 20px;
}

.dashboard .partner .images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .partner .img-wrapper img {
  height: 80px;
  width: auto;
}

.dashboard .banner-song {
  height: 300px;
  margin-top: 55px;
  background-image: url("../../../assets/img/banner-song.png");
}

.dashboard .banner-song .wrapper p {
  width: 300px;
}

.dashboard .banner-song .wall {
  height: 70px;
  width: 4px;
}

.dashboard .banner-song .number {

  font-size: 60px;
  padding-left: 20px;
  font-weight: 500;
  color: black;
}

.dashboard .edukasi {
  margin-top: 0px;
  padding: 0px;
  margin-top: 60px;
}

.dashboard .edukasi h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin: 0;
  padding: 0;
}

.dashboard .images-wrapper-new {
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.dashboard .images-wrapper-new .content {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.dashboard .images-wrapper-new .content h2 {
  color: white;
}

.dashboard .ending {
  margin-top: 50px;
  margin-left: 300px;
  margin-right: 300px;
}

.dashboard .ending .content-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-page {
  border-radius: 20px;
}

.dashboard .ending .content-footer .item {
  margin-top: 30px;
}


.dashboard .ending .content-footer .item .row {
  width: 740px;
  margin-top: 50px;
  padding: 5px;
  cursor: pointer;
}

.dashboard .banner-song-mobile {
  height: 500px;
  margin-top: 55px;
  background-image: url("../../../assets/img/banner-song.png");
}

/* .dashboard .banner-song-mobile .wrapper {
  } */
.dashboard .banner-song-mobile .wrapper p {
  width: 300px;
}

.dashboard .banner-song-mobile .wall {
  height: 70px;
  width: 4px;
}

.dashboard .banner-song-mobile .number {
  font-size: 60px;
  padding-left: 20px;
  font-weight: 500;
  color: black;
}