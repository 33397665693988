.detail-lagu {
}

.detail-lagu-wrapper {
  background-color: #ffffff;
  padding-bottom: 100px;
}

.detail-lagu-wrapper .detail-composer {
  cursor: pointer;
}

.detail-lagu-wrapper .detail-composer:hover {
  color: blueviolet;
}

.detail-lagu .button-group {
  margin-top: 25px;
  margin-bottom: 25px;
}

.detail-lagu .button-group button {
  border: none;

  color: #687083;
}

.detail-lagu .button-group .active {
  border: 1px solid rgb(192, 192, 192);
  color: #9545eb;
  border-radius: 20px;
}

.detail-lagu .pencipta {
  margin-top: 40px;
}

.detail-lagu .pencipta .composer-detail {
  display: flex;
}

.detail-lagu .pencipta .composer-detail .content {
  margin-left: 16px;
}

.detail-lagu .pencipta .composer-detail img {
  width: 120px;
  height: 120px;
}

.detail-lagu .content-carousel {
  margin-top: 25px;
}

.detail-lagu .content-carousel .content {
  border: 1px solid #e4e7eb;
  width: 220px;
  height: 220px;
  border-radius: 10px;
}

.detail-lagu .content-carousel .content:hover {
  background-color: #d1d1d1;
  cursor: pointer;
}

.detail-lagu .react-multi-carousel-item {
}

.detail-lagu .lagu {
  margin-top: 60px;
}

.modal-custom {
  margin-left: 50px;
  margin-right: 50px;
}

.modal-custom .head-modal {
  display: flex;
  height: 64px;
  align-items: center;
  margin-bottom: 24px;
}

.modal-custom .head-modal .icon-lagu {
  height: 100%;
  background-color: #f4ecfd;
  border-radius: 5px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-lagu .lagu .content {
  border: 1px solid #e4e7eb;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.detail-lagu .lagu .content-mobile {
  border: 1px solid #e4e7eb;
  height: 100px;
  display: flex;
  width: 600px;

  border-radius: 10px;
}

.detail-lagu .lagu .content:hover {
  cursor: pointer;
  background-color: rgb(236, 236, 236);
  box-shadow: 2px 2px 2px 2px rgb(236, 236, 236);
}

.detail-lagu .lagu .content .icon-lagu {
  margin-left: 20px;
  background-color: #f4ecfd;
  border-radius: 5px;
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.detail-lagu #Modal {
  border: 1px solid red;
}
