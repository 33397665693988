.login-caris-new {
  display: flex;
}
.login-caris {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.login-caris .content {
  width: 30%;
  min-height: 500px;
}

.login-caris .content .title {
  font-size: 20px;
  margin-top: 40px;
  font-weight: bold;
}

.login-caris .content .header {
  text-align: center;
}

.login-caris .content .input-form {
  text-align: center;
  margin-top: 40px;
}

/* media query form mobile size */
@media (max-width: 768px) {
  .login-caris {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }

  .login-caris .content {
    width: 80%;
    min-height: 500px;
  }
  .login-caris .content .title {
    font-size: 20px;
    margin-top: 40px;
    font-weight: bold;
  }
  .login-caris .content .header {
    text-align: center;
  }

  .login-caris .content .input-form {
    text-align: center;
    margin-top: 40px;
  }
}
